import { render, staticRenderFns } from "./project.vue?vue&type=template&id=abbb1b7a&scoped=true"
import script from "./project.vue?vue&type=script&lang=js"
export * from "./project.vue?vue&type=script&lang=js"
import style0 from "./project.vue?vue&type=style&index=0&id=abbb1b7a&prod&lang=css"
import style1 from "./project.vue?vue&type=style&index=1&id=abbb1b7a&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"
import style2 from "./project.vue?vue&type=style&index=2&id=abbb1b7a&prod&rel=stylesheet%2Fscss&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abbb1b7a",
  null
  
)

export default component.exports